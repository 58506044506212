
export default function (ctx, inject) {
    const apiOrRedis = function (url, config) {
        const options = JSON.parse(`{"host":"localhost","port":"3003","protocol":"http","isEnabled":true}`)
        const SSRBaseURL = `${options.protocol}://${options.host}:${options.port}`

        const baseURL = process.server ? SSRBaseURL : document?.location?.origin || SSRBaseURL;
        // console.log('isEnabled', options.isEnabled)
        const { $axios,/* store, i18n, */ } = ctx;
        // try to get from api, if the api server is down, get from redis,
        // the redis key is entity: locale: url, if the api responded with success,
        // it will save the data to redis, the redis functionality will be available only in server side
        const fullPath = $axios.getUri({ url, params: config?.params, query: config?.query });

        // const entity = store.state.entity;
        // const locale = i18n.locale;
        // const redisKey = `${entity}:${locale}:${fullPath}`;
        return new Promise((resolve, reject) => {
            $axios.$get(url, config).then(async (response) => {
                if (process.server && options.isEnabled) {
                    const parsedUrl = fullPath.replace(/^\//, '');
                    // save to redis
                    await $axios.$post('/cache/' + parsedUrl, response, {
                        ...config,
                        baseURL
                    }).catch((e) => {
                        console.error("Unable to save to redis", e);
                    });
                }
                resolve(response);
            }).catch(async (e) => {
                if (options.isEnabled) {
                    if (process.server) {
                        console.error("API failed, trying redis... ", {
                            url,
                            entity: e.config.headers.Entity,
                            language: e.config.headers.Language,
                            message: e.message
                        });
                    }

                    const parsedUrl = fullPath.replace(/^\//, '');

                    await $axios.$get('/cache/' + parsedUrl, { ...config, baseURL }).then((response) => {
                        if (process.server) {
                            console.info("Redis responded successfully");
                        }
                        resolve(response);
                    }).catch((e) => {
                        if (process.server) {
                            console.error("Unable to get from redis", e);
                        }
                        reject(e);
                    });
                } else {
                    reject(e);
                }
            })
        });
    };

    const nuxtApi = function () {
        // create self api on nuxt axios instance
        const { $axios, req } = ctx;

        let baseURL;
        const protocol = process.env.NUXT_ENV_NUXT_API_PROTOCOL || 'https';

        // When running on the server
        if (req) {
            baseURL = protocol + '://' + req.headers.host;
        }
        // When running on the client
        else {
            baseURL = window.location.origin;
        }

        const instance = $axios.create({
            baseURL
        });
        // console.log("🚀 ~ file: RedisPlugin.js:84 ~ nuxtApi ~ baseURL:", baseURL)

        instance.setBaseURL(baseURL)

        instance.onRequest((config) => {
            console.log('Making request to ' + config.baseURL + config.url)
        })

        return instance
    };

    inject('apiOrRedis', apiOrRedis)
    inject('nuxtApi', nuxtApi())
}